// $base-font-size: 1.4rem;
// font-weight 300;400;500;600
$app-bg:#f9f6f3;
$text-color: #444444;
$dark-blue:#3e4b5e;
$gray: #9ea0a5;

$auth-bg: #3b9665;
$emerald: #36b37e;
$primary:#288046;
$secondary:#b6e59f;

$white: #ffffff;
$black: #000000;
$border-color: #f0f0f0;
$border-color-base: #d9d9d9;

%title {
	font-family: "Open Sans", sans-serif;
	font-weight: 400;
}
%title1 {
	@extend %title;

	font-size: 2.8rem;
	font-weight: 400;
}

@import "./src/assets/scss/variables.scss";
                				@import "./src/assets/scss/mixins.scss";
                				@import './fontface';
@import './normalize';

:root {
	--bg-color: #{$app-bg};

	font-size: 62.75%;
	font-family: 'Rubik', sans-serif;
}

// html {
//   font-size: 62.75%;
// }

// body {
//   font-size: $base-font-size;
//   color: $text-color;
//   background-color: var(--bg-color);
// }

h1 {
	@extend %title1;
}

								
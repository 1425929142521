@import "./src/assets/scss/variables.scss";
                				@import "./src/assets/scss/mixins.scss";
                				
:root {
	::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}

	::-webkit-scrollbar-track {
		border-radius: 0;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 5px;
		background: rgba($gray, 70%);

		&:hover {
			background: $text-color;
		}
	}

	&[scroll='hide'] {
		::-webkit-scrollbar {
			width: 0;
		}
	}

	/* Track */
}

.wrapper {
	display: flex;
	width: 100%;
	height: 100vh;
	justify-content: center;
	align-items: center;
	background-color: $auth-bg;
}

.custome_card {
	&[class^=ant-card],
	&[class*=ant-card] {
		border-radius: 1rem;
		width: 48rem;
		align-items: center;
		padding: 3rem 0;
	}
}

.app_logo_wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 2rem;

	.app_logo {
		width: 5rem;
		margin-bottom: 1rem;
	}

	.app_name {
		width: 14rem;
		color: $primary;
		text-align: center;
		font-size: 2rem;
		font-weight: 400;
		margin-bottom: 1rem;
	}

	.admin_label {
		color: $primary;
		font-size: 1.4rem;
		text-transform: uppercase;
		font-weight: 400;
	}
}

								